
// import { Header } from "../partials";
import './Privacy.css';

const Privacy = () => {

  return (
    <div className="privacy">
      {/* <section className="privacy--header">
        <Header />
      </section> */}
      <section className="privacy--main wrapper--container">
        <div className="privacy--title">
          <h1 className="privacy--heading">Data Privacy</h1>
        </div>
        <div className="privacy--content">
          <p>
            Recent proliferation – leading to an increase in adoption and use, of cloud and digital solutions is driving a huge transformation across varied industries. At the center of this innovation boom are the enterprise cloud applications which have removed barriers associated with the legacy technologies. Hence granting an enormous and direct access to end-users to disseminate and share information in real-time.
          </p>
          <p>
            The change of users' behaviors and taste in digital content consumption from information overload has created many gaps leading to data breaches. The exploitation of these weak links to access personal information for malicious use has led practitioners to call for stringent privacy regulations. We support our customers in delivering products that are compliant and preserve the privacy of users.
          </p>
          <p>
            While efficient implementation is a challenge to many organizations, our solutions streamline the process by leveraging and adopting existing and evaluated guiding principles. The seven foundational principles of Privacy by Design are:
          </p>
          <div>
            <ul>
              <li className='privacy--list'>Proactive not Reactive</li>
              <li className='privacy--list'>Privacy as the Default Setting</li>
              <li className='privacy--list'>Privacy Embedded into Design</li>
              <li className='privacy--list'>Full Functionality</li>
              <li className='privacy--list'>End-to-End Security</li>
              <li className='privacy--list'>Visibility and Transparency</li>
              <li className='privacy--list'>Asset Management</li>
              <li className='privacy--list'>Insurance Coverage</li>
              <li className='privacy--list'>Respect for User Privacy</li>
            </ul>
          </div>
          <p>
            To learn more privacy our various offerings or enquiries, do send us an email &nbsp;<a href="mailto:info@barestripe.com">info@barestripe.com</a> or call:&nbsp;<a href="tel:+2348032130560">+234 803 213 0560</a>
          </p>
        </div>
      </section>
    </div>
  );
};

export default Privacy;