import Home from './Home';
import { Header, Subscription, Main, Footer } from "../partials";

const HomeContainer = () => {

  return (
    // <div className='flex flex-row flex-wrap justify-between content-center gap-5 md:gap-0 px-2.5 py-5 bg-slate-100'>
    <div>
      <Header />
      <Home />
      <Subscription />
      <Main />
      <Footer />
    </div>      
  );
};

export default HomeContainer;