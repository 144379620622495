import Privacy from './Privacy';
import { Footer, Header, Main, Subscription } from "../partials";

const PrivacyContainer = () => {

  return (
    <div className='flex flex-row flex-wrap justify-between content-center gap-5 md:gap-0 px-2.5 py-5 bg-slate-100'>
      <Header />
      <Privacy />
      <Subscription />
      <Main />
      <Footer />
    </div>      
  );
};

export default PrivacyContainer;