import { Link } from "react-router-dom";
import "./Main.css";

const Main = () => {
  // const classNane = 'Main';

  return (
    <div className="main">
			<div className="main--wrapper">
				<h3 className="main--title">Barestripe</h3>
				<div className="main--items">
					<div className="main--item">
						<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 128 128" xmlSpace="preserve">
							<g>
								<g>
									<path d="M64,0C39.7,0,20,19.7,20,44s44,84,44,84s44-59.7,44-84S88.3,0,64,0z M28,44C28,24.1,44.1,8,64,8s36,16.1,36,36
										c0,13.8-20,47.2-36,70.2C48,91.2,28,57.8,28,44z M64,24c-11,0-20,9-20,20s9,20,20,20s20-9,20-20S75,24,64,24z M64,56
										c-6.6,0-12-5.4-12-12s5.4-12,12-12s12,5.4,12,12S70.6,56,64,56z"/>
								</g>
							</g>
						</svg>
						<span>Lagos, Nigeria.</span>
					</div>
					<div className="main--item">
						<svg version="1.1" id="web-icon" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve">
							<path d="M256,0C114.6,0,0,114.6,0,256c0,141.4,114.6,256,256,256c141.4,0,256-114.6,256-256C512,114.6,397.4,0,256,0 M238,142.8
							c-25.3-1.6-49.3-6.5-71.3-14.1c17.7-36.1,42.5-65.3,71.3-84.4V142.8z M238,178.9v59h-99.6c1.6-26.8,6.7-52.3,14.5-75.8
							C179.2,171.5,207.9,177.2,238,178.9 M238,274v58.4c-30.2,1.7-59,7.6-85.5,17c-7.7-23.4-12.6-48.7-14.1-75.4H238z M238,368.6v99.9
							c-29.1-19.3-54.2-49-71.9-85.6C188.2,375.2,212.4,370.2,238,368.6 M274,368.6c25.5,1.6,49.7,6.6,71.9,14.4
							c-17.8,36.6-42.8,66.3-71.9,85.6V368.6z M274,332.4V274h99.6c-1.5,26.7-6.4,52-14.1,75.4C333.1,340,304.3,334.1,274,332.4
							M274,237.9v-59c30-1.7,58.7-7.5,85.1-16.8c7.8,23.5,12.9,49,14.4,75.8H274z M274,142.8V44.2c28.8,19.1,53.6,48.4,71.3,84.4
							C323.4,136.3,299.3,141.2,274,142.8 M336.8,51.5c26.3,10.4,50.1,25.8,70.3,45c-8.8,6.5-18.4,12.5-28.5,17.9
							C367.3,90.9,353.2,69.8,336.8,51.5 M133.3,114.4c-10.2-5.3-19.7-11.4-28.5-17.9c20.2-19.2,44-34.6,70.3-45
							C158.8,69.8,144.7,90.9,133.3,114.4 M119.6,147.8c-9.6,28-15.6,58.4-17.3,90.1H36.9c3.5-42.8,19.2-82.2,43.8-114.5
							C92.6,132.5,105.7,140.7,119.6,147.8 M102.3,274c1.6,31.6,7.5,61.9,16.9,89.7c-13.9,7.1-26.9,15.3-38.8,24.5
							C56,355.9,40.4,316.7,36.9,274L102.3,274L102.3,274z M132.8,397.3c11.2,23.3,25.1,44.5,41.2,62.7c-26-10.5-49.6-25.8-69.6-44.9
							C113.2,408.7,122.7,402.7,132.8,397.3 M379.2,397.3c10.1,5.3,19.6,11.4,28.3,17.8c-19.9,19-43.5,34.3-69.5,44.8
							C354.1,441.8,368,420.7,379.2,397.3 M392.8,363.8c9.4-27.9,15.3-58.1,16.9-89.7h65.4c-3.5,42.6-19.1,81.8-43.5,114.2
							C419.7,379.1,406.7,370.9,392.8,363.8 M409.7,237.9c-1.7-31.8-7.7-62.1-17.2-90.1c13.9-7.1,27-15.3,38.9-24.4
							c24.6,32.4,40.3,71.7,43.8,114.5L409.7,237.9L409.7,237.9z"/>
						</svg>
						<a href="https://barestripe.com" className="main--link">Barestripe.com</a>
					</div>
					{/* <!-- <div className="main--item">
						<p>The SaaS platform, driven by innovation,</p>
						<p> and made affordable for every day users.</p>
					</div> --> */}
				</div>
			</div>
			<div className="main--wrapper">
				<h3 className="main--title">Contact us</h3>
				<div className="main--items">
					<div className="main--item">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="inline-block h-4 w-4 fill-current">
							<path d="M13.04 14.69l1.07-2.14a1 1 0 0 1 1.2-.5l6 2A1 1 0 0 1 22 15v5a2 2 0 0 1-2 2h-2A16 16 0 0 1 2 6V4c0-1.1.9-2 2-2h5a1 1 0 0 1 .95.68l2 6a1 1 0 0 1-.5 1.21L9.3 10.96a10.05 10.05 0 0 0 3.73 3.73zM8.28 4H4v2a14 14 0 0 0 14 14h2v-4.28l-4.5-1.5-1.12 2.26a1 1 0 0 1-1.3.46 12.04 12.04 0 0 1-6.02-6.01 1 1 0 0 1 .46-1.3l2.26-1.14L8.28 4z"></path>
						</svg> 
						<a href="tel:+2348032130560" className="main--link">+234 803 213 0560</a>
					</div> 
					<div className="main--item">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="inline-block h-4 w-4 fill-current">
							<path d="M4 4h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2zm16 3.38V6H4v1.38l8 4 8-4zm0 2.24l-7.55 3.77a1 1 0 0 1-.9 0L4 9.62V18h16V9.62z"></path>
						</svg> 
						<a href="mailto:support@barestripe.com" className="main--link whitespace-nowrap">support@barestripe.com</a>
					</div>
				</div>
			</div>
			<div className="main--wrapper">
				<h3 className="main--title">Quick links</h3>
				<div>
					{/* <div className="main--item">
						<a className="main--link" href="https://docs.barestripe.com" target="_blank" rel="noopener noreferrer">Documentation</a>
					</div> */}
					<div className="main--item">
						<Link className="main--link" to="/about"> About</Link>
					</div> 
					<div className="main--item">
						<Link className="main--link" to="/about"> Terms & Conditions</Link>
					</div>
					<div className="main--item">
						<Link className="main--link" to="/about"> Privacy Policy</Link>
					</div>
				</div>
			</div>
			<div className="main--wrapper">
				<h3 className="main--title">Connect on</h3>
				<div className="connect--wrapper">
					<ul className="connect--socials">
						<li className="connect--item">
							<a href="https://www.twitter.com/barestripe"  target="_blank" rel="noopener noreferrer">
								<span className="connect--link">
									<svg className="twitter" viewBox="0 0 123 100" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
										<path fill="currentColor" d="M123.053259,11.8361853 C118.527206,13.8434916 113.658912,15.2009229 108.552201,15.8123438 C113.766583,12.6860219 117.76966,7.74081907 119.653913,1.84579888 C114.777927,4.73755047 109.371275,6.8371467 103.62238,7.97154393 C99.0155739,3.06479523 92.4552971,0 85.1951548,0 C71.2516824,0 59.9500096,11.3016728 59.9500096,25.2451452 C59.9500096,27.2216881 60.1730436,29.1482407 60.6037301,30.997885 C39.619304,29.9442415 21.0190348,19.8923284 8.56758316,4.61834263 C6.39492405,8.34839454 5.14900981,12.6860219 5.14900981,17.3120554 C5.14900981,26.0680638 9.60584503,33.7973467 16.3776197,38.3233993 C12.2399539,38.1926553 8.34839454,37.058258 4.94520285,35.1663142 C4.94135743,35.2739858 4.94135743,35.377812 4.94135743,35.4854836 C4.94135743,47.7177466 13.6435301,57.9196308 25.1951548,60.2384157 C23.0763315,60.8152278 20.8459912,61.122861 18.542588,61.122861 C16.9159777,61.122861 15.3355124,60.965199 13.7935012,60.6729475 C17.0044222,70.7017881 26.329552,78.0003845 37.3774274,78.2041915 C28.7367814,84.9759662 17.8504134,89.0098058 6.02191886,89.0098058 C3.98384926,89.0098058 1.97654297,88.890598 0,88.6598731 C11.1709287,95.8200346 24.4414536,100 38.70025,100 C85.1374736,100 110.528744,61.5304749 110.528744,28.1715055 C110.528744,27.0755624 110.505672,25.9873101 110.455682,24.9029033 C115.389348,21.345895 119.669294,16.900596 123.053259,11.8361853"></path>
									</svg>
								</span>
								<span className="">Twitter</span>
								<span className="sr-only">Follow @barestripe on twitter</span>
							</a>
						</li>
						<li className="connect--item">
							<a href="https://www.facebook.com/barestripe"  target="_blank" rel="noopener noreferrer">
								<span className="connect--link">
									<svg className="facebook" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
										<path fill="currentColor" d="M95.834375,0 L4.16875,0 C1.865625,0 0,1.865625 0,4.165625 L0,95.834375 C0,98.134375 1.865625,100 4.16875,100 L53.125,100 L53.125,62.5 L40.625,62.5 L40.625,46.875 L53.125,46.875 L53.125,34.375 C53.125,21.459375 61.303125,15.103125 72.825,15.103125 C78.34375,15.103125 83.0875,15.515625 84.46875,15.696875 L84.46875,29.196875 L76.478125,29.2 C70.2125,29.2 68.75,32.178125 68.75,36.546875 L68.75,46.875 L84.375,46.875 L81.25,62.5 L68.75,62.5 L69,100 L95.834375,100 C98.134375,100 100,98.134375 100,95.834375 L100,4.165625 C100,1.865625 98.134375,0 95.834375,0" ></path>
									</svg>
								</span>
								<span className="">Facebook</span>
								<span className="sr-only">Follow @barestripe on facebook</span>
							</a>
						</li>
						<li className="connect--item">
							<a href="https://www.instagram.com/barestripe"  target="_blank" rel="noopener noreferrer">
								<span className="connect--link">
									<svg className="instagram" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
										<path fill="currentColor" d="M49.8522167,35.8128079 C57.5862069,35.8128079 63.8916256,42.1182266 63.8916256,49.8522167 C63.8916256,57.5862069 57.5862069,63.8916256 49.8522167,63.8916256 C42.1182266,63.8916256 35.8128079,57.5862069 35.8128079,49.8522167 C35.8128079,42.1182266 42.1182266,35.8128079 49.8522167,35.8128079 Z M82.4630542,7.10542736e-15 C91.9704433,7.10542736e-15 99.7044335,7.73399015 99.7536946,17.2413793 L99.7536946,82.5123153 C99.7536946,92.0197044 92.0197044,99.7536946 82.5123153,99.7536946 L17.2413793,99.7536946 C7.73399015,99.7536946 7.10542736e-15,92.0197044 7.10542736e-15,82.5123153 L7.10542736e-15,17.2413793 C7.10542736e-15,7.73399015 7.73399015,7.10542736e-15 17.2413793,7.10542736e-15 L82.4630542,7.10542736e-15 Z M49.8522167,78.6699507 C65.7142857,78.6699507 78.6699507,65.7635468 78.6699507,49.8522167 C78.6699507,33.9408867 65.7635468,21.0344828 49.8522167,21.0344828 C33.9408867,21.0344828 21.0344828,33.9408867 21.0344828,49.8522167 C21.0344828,65.7635468 33.9901478,78.6699507 49.8522167,78.6699507 Z M89.2118227,17.635468 C89.2118227,13.6945813 86.0098522,10.4926108 82.0689655,10.4926108 C78.1280788,10.4926108 74.9261084,13.6945813 74.9261084,17.635468 C74.9261084,21.5763547 78.1280788,24.7783251 82.0689655,24.7783251 C86.0098522,24.7783251 89.2118227,21.5763547 89.2118227,17.635468 Z"  fillRule="nonzero"></path> 
									</svg>
								</span>
								<span className="">Instagram</span>
								<span className="sr-only">Follow @barestripe on instagram</span>
							</a>
						</li>
						<li className="connect--item">
							<a href="https://www.linkedin.com/in/barestripe"  target="_blank" rel="noopener noreferrer">
								<span className="connect--link">
									<svg className="linkedin" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
										<path fill="currentColor" d="M100,100 L79.0849558,100 L79.0849558,67.506737 C79.0849558,59.7575358 78.9527495,49.7899014 68.217594,49.7899014 C57.3370116,49.7899014 55.6756185,58.2348243 55.6756185,66.9510349 L55.6756185,100 L34.7826087,100 L34.7826087,33.1800945 L54.8339048,33.1800945 L54.8339048,42.3163634 L55.1203518,42.3163634 C57.9099058,37.0612585 64.7317533,31.5217391 74.9072352,31.5217391 C96.0866921,31.5217391 100,45.3574108 100,63.3542853 L100,100 Z M11.9630376,23.9130435 C5.33862497,23.9130435 3.55271368e-15,18.557043 3.55271368e-15,11.9543498 C3.55271368e-15,5.35600047 5.33862497,3.55271368e-15 11.9630376,3.55271368e-15 C18.557043,3.55271368e-15 23.9130435,5.35600047 23.9130435,11.9543498 C23.9130435,18.557043 18.557043,23.9130435 11.9630376,23.9130435 Z M21.7391304,100 L1.08695652,100 L1.08695652,32.6086957 L21.7391304,32.6086957 L21.7391304,100 Z"></path>
									</svg>
								</span>
								<span className="">Linkedin</span>
								<span className="sr-only">Follow @barestripe on linkedin</span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
  );
};

export default Main;