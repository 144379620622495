
// import { Header } from "../partials";
import './Blockchain.css';

const Blockchain = () => {

  return (
    <div className="about">
      {/* <section className="about--header">
        <Header />
      </section> */}
      <section className="about--main wrapper--container">
        <div className="demo--title">
          <h1 className="demo--heading">Blockchain Technology</h1>
        </div>
        <div className="about--content">
          <p>
            Blockchain Technology, often mistaken as a pseudo for cryptocurrency or other Decentralized Finance (DeFi). However, at the base of this technology is a decentralized ledger. And like the old-aged ledger systems that humans have used for hundreds of centuries, what differentiates blockchain from other centralized and distributed ledger is the decentralization, transparency, auditability, and immutability of records and or transactions embedded into its fabrics.
          </p>
          <p>
            Decentralization ensures that every participant has a complete copy of the data in a blockchain network. Transparency gives full visibility to members of the blockchain network of every information stored in the blockchain. The inability to alter a record or transaction after it is written to the network ensures the immutability. The combination of the above ensures auditability within a blockchain network.
          </p>
          <p>
          At the heart of the blockchain benefits, its building composition and various validation mechanisms is a “trustless technology”. We proffer solution to common use cases as in:
          </p>
          <div>
            <ul>
              <li className='blockchain--list'>Supply Chain Monitoring</li>
              <li className='blockchain--list'>Financial Services</li>
              <li className='blockchain--list'>Digital Identity</li>
              <li className='blockchain--list'>Cyber Security</li>
              <li className='blockchain--list'>Digital Voting System</li>
              <li className='blockchain--list'>Healthcare</li>
              <li className='blockchain--list'>Asset Management</li>
              <li className='blockchain--list'>Insurance Coverage</li>
              <li className='blockchain--list'>Process Improvement</li>
            </ul>
          </div>
          <p>
            To learn more about our various offerings or enquiries, do send us an email &nbsp;<a href="mailto:info@barestripe.com">info@barestripe.com</a> or call:&nbsp;<a href="tel:+2348032130560">+234 803 213 0560</a>
          </p>
        </div>
      </section>
    </div>
  );
};

export default Blockchain;