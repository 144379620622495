import { TouchEvent, useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import "./Header.css";

const Header = () => {
  const [open, setOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(getWindowWidth());
  const [company] = useState(['/blogs', '/careers', '/about', '/demo']);
  const [service] = useState(['/cloud', '/privacy', '/blockchain', '/saas']);
  const location = useLocation();
  // console.log(location.pathname);

  const closeHandler = () => {
    setOpen(!open);
    const elements = Array.from(document.querySelectorAll('.header--dropdown'));
    elements.forEach(element => {
      element.classList.remove('active');
    });
    return;
  }
  const openHandler = () => {
    setOpen(!open);
    const elements = Array.from(document.querySelectorAll('.header--dropdown'));
    elements.forEach(element => {
      element.classList.remove('active');
    });
    return;
  }
  const handleTouch = (e: TouchEvent<HTMLSpanElement>) => {
    // https://freshman.tech/snippets/typescript/fix-value-not-exist-eventtarget/
    const target = e.target as HTMLSpanElement;
    target?.nextElementSibling?.classList.toggle('active');
    const elements = Array.from(document.querySelectorAll('.header--dropdown'));
    elements.forEach(element => {
      if (element !== target?.nextElementSibling) 
        return setTimeout(function(){ element.classList.remove('active'); }, 300);
    });
  }

  // const locationHandler = () => {
  //   const company = ['/blogs', '/careers', '/about', '/demo'];
  //   if (company.includes(location.pathname) ) {
      
  //   }
  // }

  useEffect(() => {
    function handleWindowResize() {
      setWindowWidth(getWindowWidth());
      const elements = Array.from(document.querySelectorAll('.header--dropdown'));
      elements.forEach(element => {
        element.classList.remove('active');
      });
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowWidth(): boolean {
    return window.innerWidth < 600;
  }

  return (
    <div className="header">
      <div className="header--container">
        <div className="logo--mobile logo--list">
          <Link to="/" className="header--listss header--logos px-2.5">
            {/* <svg className="header--logo" viewBox="0 0 1583 828" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M731.499 137.233C729.639 136.158 727.761 135.146 725.865 134.197L725.524 134L725.512 134.021C676.437 109.683 616.29 127.405 588.546 175.537L291.448 690.967C341.516 719.921 405.518 702.772 434.401 652.663L719.815 157.502C733.985 154.188 756.299 156.652 768.755 159L731.403 137.4L731.499 137.233ZM291.231 690.92L308.755 660.517C301.745 672.678 265.331 669.186 248 665.92L291.231 690.92Z" fill="white"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M991.499 137.075C989.639 136 987.761 134.988 985.865 134.039L985.524 133.842L985.512 133.863C936.437 109.525 876.29 127.247 848.546 175.379L551.448 690.809C601.516 719.763 665.518 702.614 694.401 652.505L979.815 157.344C993.985 154.03 1016.3 156.494 1028.75 158.842L991.403 137.241L991.499 137.075ZM551.231 690.761L568.755 660.359C561.745 672.52 525.331 669.028 508 665.761L551.231 690.761Z" fill="white"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M1251.5 136.917C1249.64 135.841 1247.76 134.83 1245.87 133.881L1245.52 133.684L1245.51 133.705C1196.44 109.367 1136.29 127.089 1108.55 175.221L811.448 690.651C861.516 719.605 925.518 702.456 954.401 652.347L1239.82 157.186C1253.99 153.872 1276.3 156.336 1288.75 158.684L1251.4 137.083L1251.5 136.917ZM811.231 690.603L828.755 660.201C821.745 672.362 785.331 668.869 768 665.603L811.231 690.603Z" fill="white"/>
            </svg> */}
            <svg className="header--logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 520.29 497.11">
              <defs>
                {/* <style>.cls-1{fill:none;}.cls-2{fill:#fff;}</style> */}
              </defs>
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <path className="cls-1" fill="none" d="M364.23,239.61c-8.24-27.72-16.74-55.87-25-83.09q-4.67-15.47-9.34-30.93c-6.26-20.68-12.1-39.2-17.83-56.61C306.28,51.23,301,35.79,295.9,21.74l14.39-8.69,182.5,200.07-122.6,46.44Z" />
                  <path className="cls-1" fill="none" d="M478,209.57,304,18.82c5.7,15.75,11.07,31.59,16.27,47.48q9.31,28.31,17.9,56.81c11.46,38,23,76,34.31,114.05q1.67,5.57,3.32,11.13l7.84-3-7.84,3h0Z" />
                  <path d="M427,497.11c-.53,0-1,0-1.57-.06L9.72,467a10.47,10.47,0,0,1,.76-20.92l386.25,4.85c-7.27-27.38-14.67-55.61-22.49-85.94l-14.81-57.68q-3.68-14.28-7.29-28.58l-2-7.83,37.71-12,2.49,8.36q4.33,14.56,8.62,29.13l16.8,57.12c12.29,42,23.07,79.47,32.94,114.53l.08.31A22.61,22.61,0,0,1,427,497.11Z" />
                  <path className="cls-2" fill="#fff" d="M367.73,305.18l14.81,57.67Q395,411.3,407.93,459.64l-397.43-5a1.91,1.91,0,0,0-.16,3.81l415.7,30a14.41,14.41,0,0,0,4.81-.48,14,14,0,0,0,9.7-17.3l-.09-.33Q424.32,413.09,407.54,356l-16.79-57.12q-4.29-14.56-8.62-29.1l-21.69,6.88Q364.07,290.9,367.73,305.18Z" />
                  <path d="M141.38,349.59a10.47,10.47,0,0,1-3.73-20.26l207.2-78.5c-.14-.56-.25-1.06-.37-1.55-.26-1.1-.52-2.2-1.06-4.33-7.13-28.11-14.26-56.73-21.15-84.42Q318.4,145,314.52,129.4c-5.25-21.16-9.74-40.18-13.75-58.18-5.17-23-9-41.65-12.16-58.74v-.05A10.52,10.52,0,0,1,297,.17,10.69,10.69,0,0,1,298.91,0a10.49,10.49,0,0,1,7.18,2.84l207.79,197A20.59,20.59,0,0,1,506,234.37L144.55,349.1a10.34,10.34,0,0,1-3.17.49ZM325.63,55.3c.9,2.72,1.82,5.5,2.74,8.33,5.78,17.53,11.65,36.18,18,57q4.67,15.48,9.34,30.93c8.23,27.24,16.74,55.41,25,83.16.32,1.06.54,1.74.71,2.28l81.76-31Z" />
                  <path className="cls-2" fill="#fff" d="M508,206.05,300.24,9.1a2,2,0,0,0-1.67-.5A1.92,1.92,0,0,0,297,10.84l0,.1c3.58,19.59,7.73,39,12.09,58.41s8.93,38.71,13.7,58c9.62,38.52,19.14,77.06,28.91,115.54,1.11,4.42,1.07,4.56,2.19,9L374.6,244c-1.1-3.71-1.05-3.14-2.16-6.85-11.3-38-22.85-76-34.31-114q-8.64-28.48-17.9-56.81C315,50.41,309.66,34.57,304,18.82L478,209.57,375.76,248.29h0l-20.68,7.83h0L140.71,337.34a1.9,1.9,0,0,0,1.25,3.59L359.1,272h0l21.69-6.88,122.46-38.87.15,0a11.78,11.78,0,0,0,5-3.17A12,12,0,0,0,508,206.05Z" />
                </g>
              </g>
            </svg>
          </Link>
          <button type="button" className="hamburger" onClick={openHandler}>&#9776;</button>
        </div>
        <nav className={`header--navigation ${open? "active" : ""}`}>
          <ul className="header--lists">
            <li className="header--list logo--list">
              <Link to="/" className="logo--link">
                {/* <svg className="header--logo" viewBox="0 0 1583 828" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M731.499 137.233C729.639 136.158 727.761 135.146 725.865 134.197L725.524 134L725.512 134.021C676.437 109.683 616.29 127.405 588.546 175.537L291.448 690.967C341.516 719.921 405.518 702.772 434.401 652.663L719.815 157.502C733.985 154.188 756.299 156.652 768.755 159L731.403 137.4L731.499 137.233ZM291.231 690.92L308.755 660.517C301.745 672.678 265.331 669.186 248 665.92L291.231 690.92Z" fill="white" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M991.499 137.075C989.639 136 987.761 134.988 985.865 134.039L985.524 133.842L985.512 133.863C936.437 109.525 876.29 127.247 848.546 175.379L551.448 690.809C601.516 719.763 665.518 702.614 694.401 652.505L979.815 157.344C993.985 154.03 1016.3 156.494 1028.75 158.842L991.403 137.241L991.499 137.075ZM551.231 690.761L568.755 660.359C561.745 672.52 525.331 669.028 508 665.761L551.231 690.761Z" fill="white" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M1251.5 136.917C1249.64 135.841 1247.76 134.83 1245.87 133.881L1245.52 133.684L1245.51 133.705C1196.44 109.367 1136.29 127.089 1108.55 175.221L811.448 690.651C861.516 719.605 925.518 702.456 954.401 652.347L1239.82 157.186C1253.99 153.872 1276.3 156.336 1288.75 158.684L1251.4 137.083L1251.5 136.917ZM811.231 690.603L828.755 660.201C821.745 672.362 785.331 668.869 768 665.603L811.231 690.603Z" fill="white" />
                </svg> */}
                <svg className="header--logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 520.29 497.11">
                  <defs>
                    {/* <style>.cls-1{fill:none;}.cls-2{fill:#fff;}</style> */}
                  </defs>
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                      <path className="cls-1" fill="none" d="M364.23,239.61c-8.24-27.72-16.74-55.87-25-83.09q-4.67-15.47-9.34-30.93c-6.26-20.68-12.1-39.2-17.83-56.61C306.28,51.23,301,35.79,295.9,21.74l14.39-8.69,182.5,200.07-122.6,46.44Z" />
                      <path className="cls-1" fill="none" d="M478,209.57,304,18.82c5.7,15.75,11.07,31.59,16.27,47.48q9.31,28.31,17.9,56.81c11.46,38,23,76,34.31,114.05q1.67,5.57,3.32,11.13l7.84-3-7.84,3h0Z" />
                      <path d="M427,497.11c-.53,0-1,0-1.57-.06L9.72,467a10.47,10.47,0,0,1,.76-20.92l386.25,4.85c-7.27-27.38-14.67-55.61-22.49-85.94l-14.81-57.68q-3.68-14.28-7.29-28.58l-2-7.83,37.71-12,2.49,8.36q4.33,14.56,8.62,29.13l16.8,57.12c12.29,42,23.07,79.47,32.94,114.53l.08.31A22.61,22.61,0,0,1,427,497.11Z" />
                      <path className="cls-2" fill="#fff" d="M367.73,305.18l14.81,57.67Q395,411.3,407.93,459.64l-397.43-5a1.91,1.91,0,0,0-.16,3.81l415.7,30a14.41,14.41,0,0,0,4.81-.48,14,14,0,0,0,9.7-17.3l-.09-.33Q424.32,413.09,407.54,356l-16.79-57.12q-4.29-14.56-8.62-29.1l-21.69,6.88Q364.07,290.9,367.73,305.18Z" />
                      <path d="M141.38,349.59a10.47,10.47,0,0,1-3.73-20.26l207.2-78.5c-.14-.56-.25-1.06-.37-1.55-.26-1.1-.52-2.2-1.06-4.33-7.13-28.11-14.26-56.73-21.15-84.42Q318.4,145,314.52,129.4c-5.25-21.16-9.74-40.18-13.75-58.18-5.17-23-9-41.65-12.16-58.74v-.05A10.52,10.52,0,0,1,297,.17,10.69,10.69,0,0,1,298.91,0a10.49,10.49,0,0,1,7.18,2.84l207.79,197A20.59,20.59,0,0,1,506,234.37L144.55,349.1a10.34,10.34,0,0,1-3.17.49ZM325.63,55.3c.9,2.72,1.82,5.5,2.74,8.33,5.78,17.53,11.65,36.18,18,57q4.67,15.48,9.34,30.93c8.23,27.24,16.74,55.41,25,83.16.32,1.06.54,1.74.71,2.28l81.76-31Z" />
                      <path className="cls-2" fill="#fff" d="M508,206.05,300.24,9.1a2,2,0,0,0-1.67-.5A1.92,1.92,0,0,0,297,10.84l0,.1c3.58,19.59,7.73,39,12.09,58.41s8.93,38.71,13.7,58c9.62,38.52,19.14,77.06,28.91,115.54,1.11,4.42,1.07,4.56,2.19,9L374.6,244c-1.1-3.71-1.05-3.14-2.16-6.85-11.3-38-22.85-76-34.31-114q-8.64-28.48-17.9-56.81C315,50.41,309.66,34.57,304,18.82L478,209.57,375.76,248.29h0l-20.68,7.83h0L140.71,337.34a1.9,1.9,0,0,0,1.25,3.59L359.1,272h0l21.69-6.88,122.46-38.87.15,0a11.78,11.78,0,0,0,5-3.17A12,12,0,0,0,508,206.05Z" />
                    </g>
                  </g>
                </svg>
              </Link>
              <button type="button" className="hamburger" onClick={closeHandler}>&#10005;</button>
            </li>
            <li className="header--list"><NavLink end to="/" className="header--link px-2.5"> Home </NavLink></li>
            <li className="header--list"><NavLink to="/platform" className="header--link px-2.5"> Platforms </NavLink></li>
            <li className="header--list">
              {windowWidth?
              (<span className="header--link link--dropdown" id="company" onTouchStart={handleTouch}> Company </span>)
              :
              (<NavLink to="/about" className={`header--link link--dropdown px-2.5 ${company.includes(location.pathname)? "active" : ""}`}> Company </NavLink>)
              }
              <div className="header--dropdown">
                <ul className="dropdown--lists">
                  <li className="dropdown--list"><NavLink to="/about" className="dropdown--link px-2.5"> About Us </NavLink></li>
                  <li className="dropdown--list"><NavLink to="/blogs" className="dropdown--link px-2.5"> Blog </NavLink></li>
                  <li className="dropdown--list"><NavLink to="/careers" className="dropdown--link px-2.5"> Careers </NavLink></li>
                  <li className="dropdown--list"><NavLink to="/demo" className="dropdown--link px-2.5"> Demo </NavLink></li>
                </ul>
              </div>
            </li>
            <li className="header--list" id="px-2.6">
              {windowWidth?
              (<span className="header--link link--dropdown px-2.5" id="services" onTouchStart={handleTouch}> Services </span>)
              :
              (<NavLink to="/cloud" className={`header--link link--dropdown px-2.5 ${service.includes(location.pathname)? "active" : ""}`}> Services </NavLink>)
              }
              <div className="header--dropdown">
                <ul className="dropdown--lists">
                  <li className="dropdown--list"><NavLink to="/cloud" className="dropdown--link px-2.5"> Cloud Applications </NavLink></li>
                  <li className="dropdown--list"><NavLink to="/privacy" className="dropdown--link px-2.5"> Data Privacy </NavLink></li>
                  <li className="dropdown--list"><NavLink to="/blockchain" className="dropdown--link px-2.5"> Blockchain </NavLink></li>
                  {/* <li className="dropdown--list"><NavLink to="/saas" className="dropdown--link px-2.5"> SaaS Applications </NavLink></li> */}
                </ul>
              </div>
            </li>
            <li className="header--list"><NavLink to="/contact" className="header--link px-2.5"> Contact </NavLink></li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Header;