import './Footer.css';

const Footer = () => {

  return (
    <div>
      <footer className="footer">
        <span>Copyright &copy; {new Date().getFullYear()} <a href="https://barestripe.com">Barestripe</a></span>	|  
        <span>All right reserved.</span>
      </footer>
    </div>
  );
};

export default Footer;